$color-login-bg: $color-grey--light;

.login-container {
  margin: 0 auto;
  padding: pinpoint-spacing(base);

  .page-title-wrapper {
    width: 100%;
    padding: 0;

    @include mq($from: desktop) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .page-title {
    @include pinpoint-font-size(24px);
    text-align: left;
    margin-bottom: pinpoint-spacing(small);
    gap: pinpoint-spacing(tiny);
    font-weight: bold;

    @include mq($from: desktop) {
      align-items: center;
      gap: pinpoint-spacing(base);
    }
  }

  .login-grid {
    display: grid;
    gap: pinpoint-spacing(small);

    @include mq($until: desktop) {
      gap: pinpoint-spacing(small);
    }

    @include mq($from: desktop) {
      grid-template-columns: repeat(3, 1fr);
      gap: pinpoint-spacing(base);
    }
  }

  .persistent {
    @include mq($from: desktop) {
      order: 2;
    }

    // overrides .field:not(.search) styles which forces a 12px margin
    &.field {
      margin-bottom: pinpoint-spacing(base);
    }

    .tooltip {
      .toggle {
        display: none;
      }

      .content {
        @include pinpoint-hidden-visually();
      }
    }
  }

  .box {
    padding: pinpoint-spacing(base);
    font-size: 16px;
    background-color: $color-login-bg;

    // hide content if the URL does not contain the fragment for the relevant section.
    // Reduces need for JS to toggle the accordions open and closed.
    &:not(:target) .box__accordion ~ * {
      @include mq($until: desktop) {
        display: none;
      }
    }

    &:not(:target) .box__title:after {
      @include mq($until: desktop) {
        content: '+';
      }
    }

    &:target .box__title:after {
      @include mq($until: desktop) {
        content: '-';
      }
    }

    // .box__accordion
    &__accordion:hover {
      text-decoration: none;
    }

    // .box-title
    &__title {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-primary;

      &::after {
        font-size: 32px;
        font-weight: 200;
      }

      @include mq($from: desktop) {
        padding: pinpoint-spacing(small);
        font-size: 24px;
        font-weight: bold;
        color: $color-black;
        justify-content: center;
        text-align: center;
        border-bottom: 2px solid $color-secondary;
      }
    }

    // .box--login-form
    &--login-form {
      @include mq($from: desktop) {
        align-self: start;
      }

      .fieldset > * + * {
        margin-top: pinpoint-spacing(base);
      }

      .field:not(.choice) .label {
        display: inline-block;
        font-size: 18px;
        margin-bottom: pinpoint-spacing(tiny);
      }
    }

    // .block--register-account
    &--register-account [data-content-type="heading"] {
      margin: pinpoint-spacing(small) 0;
      max-width: 240px;
      padding: pinpoint-spacing(small) 0;
      font-size: 16px;
      border-bottom: 1px solid $color-secondary;

      &::after {
        content: none;
      }
    }

    .actions-toolbar {
      display: flex;
      flex-direction: column;

      .primary {
        @include mq($from: desktop) {
          order: 3;
        }
      }

      .secondary {
        @include mq($until: desktop) {
          text-align: center;
          margin-top: pinpoint-spacing(base);
        }

        @include mq($from: desktop) {
          order: 1;
          margin-bottom: pinpoint-spacing(base);
          color: $color-grey;
        }

        a {
          color: $color-black;
          text-decoration: underline;

          @include mq($from: desktop) {
            color: $color-grey;
          }
        }
      }
    }

    .have-account {
      margin-top: pinpoint-spacing(base);

      @include mq($until: desktop) {
        border-top: 2px solid rgba($color-grey, 0.25);
        padding-top: pinpoint-spacing(base);
      }

      a {
        text-decoration: underline;
      }
    }

    .block-content {
      margin-top: pinpoint-spacing(base);

        ul {
            margin: 0;
            padding: 0;
            list-style: none !important;
        }
    }

    .button {
      width: 100%;
    }

    // ensure the button for the CTA blocks always sticks to the bottom of the container on desktop devices
    &:not(.box--login-form) {
      @include mq($from: desktop) {
        display: flex;
        flex-direction: column;

        .button {
          margin-top: auto;
        }
      }
    }
  }
}

.login-title-text {
  @include mq($from: desktop) {
    width: 80%;
    margin: 0 auto pinpoint-spacing(base);
    text-align: center;
  }
}

.customer-account-create .login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;

  .toggle-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toggle {
      width: 50%;
      padding: pinpoint-spacing(tiny);
      box-sizing: border-box;
      text-align: center;
      border-radius: 3px 3px 0 0;
      cursor: pointer;

      a {
        width: 100%;
        display: block;
        color: $color-black;
      }

      &.register {
        background-color: $color-grey--light;
      }

      &.login {
        background-color: $color-white;
      }
    }
  }
}

.login-register-container {
  width: 100%;
  padding: pinpoint-spacing(base);
  background-color: $color-grey--light;
  border-radius: 0 3px 3px 3px;

  .form-create-account, .block-customer-login {
    width: 100%;
    background-color: $color-grey--light;

    .input-text {
      width: 100%;
      margin-bottom: pinpoint-spacing(base);
    }

    button {
      width: 100%;
      margin-top: pinpoint-spacing(base);
    }

    .field.note, .block-title, .legend {
      @include pinpoint-hidden-visually();
    }

    .actions-toolbar {
      .secondary {
        text-align: center;

        .action.back {
          display: none;
        }
      }
    }

    .have-account {
      padding-top: pinpoint-spacing(base);
      margin-top: pinpoint-spacing(small);
      border-top: solid 1px $color-grey;
    }

    .register-btn {
      margin-top: pinpoint-spacing(small);
      color: $color-primary;
      border: solid 1px $color-primary;

      a {
        display: block;
        width: 100%;
      }
    }

    .action.remind {
      color: $color-grey;
      @include pinpoint-font-size(12px);
    }
  }

  .form-create-account, .block-customer-login {


    &.active {
      display: block;
      background-color: $color-grey--light;
    }
  }

  &.active {
    border-radius: 3px 0 3px 3px;
  }

  .field.choice.newsletter {
    margin-top: pinpoint-spacing(small);
  }
}

.block-new-company .block-title {
  margin-bottom: 12px;
}

// Pagebuilder styles
// Ensure styles are consistent across the page with pagebuilder content
#html-body .login-container .pagebuilder-column-group {
  .pagebuilder-column-line {
    flex-wrap: nowrap;
    margin-bottom: pinpoint-spacing(small);
  }

  .pagebuilder-column {
    flex-basis: auto;
    flex-grow: 0;
    width: auto;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      flex-shrink: 0;
      width: 32px;
    }

    &:last-of-type {
      flex-shrink: 1;
      margin-left: pinpoint-spacing(small);
    }
  }

  &:last-of-type {
    margin-bottom: pinpoint-spacing(base);
  }

  p {
    margin-bottom: 0;
  }

  img {
    max-width: 32px;
    max-height: 32px;
  }
}

.block.block-amazon-login {
    padding: pinpoint-spacing(base);
    font-size: 16px;
    background-color: $color-login-bg;
    margin: 32px 0 0;
    text-align: center;
}

@import 'login-register-form__extend';
