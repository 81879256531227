.login-container {
  margin: 0 auto;
  padding: 24px;
}
.login-container .page-title-wrapper {
  width: 100%;
  padding: 0;
}
@media (min-width: 75em) {
  .login-container .page-title-wrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.login-container .page-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 12px;
  gap: 6px;
  font-weight: bold;
}
@media (min-width: 75em) {
  .login-container .page-title {
    align-items: center;
    gap: 24px;
  }
}
.login-container .login-grid {
  display: grid;
  gap: 12px;
}
@media (max-width: 74.99em) {
  .login-container .login-grid {
    gap: 12px;
  }
}
@media (min-width: 75em) {
  .login-container .login-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}
@media (min-width: 75em) {
  .login-container .persistent {
    order: 2;
  }
}
.login-container .persistent.field {
  margin-bottom: 24px;
}
.login-container .persistent .tooltip .toggle {
  display: none;
}
.login-container .persistent .tooltip .content {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.login-container .box {
  padding: 24px;
  font-size: 16px;
  background-color: #ECECEC;
}
@media (max-width: 74.99em) {
  .login-container .box:not(:target) .box__accordion ~ * {
    display: none;
  }
}
@media (max-width: 74.99em) {
  .login-container .box:not(:target) .box__title:after {
    content: "+";
  }
}
@media (max-width: 74.99em) {
  .login-container .box:target .box__title:after {
    content: "-";
  }
}
.login-container .box__accordion:hover {
  text-decoration: none;
}
.login-container .box__title {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #009640;
}
.login-container .box__title::after {
  font-size: 32px;
  font-weight: 200;
}
@media (min-width: 75em) {
  .login-container .box__title {
    padding: 12px;
    font-size: 24px;
    font-weight: bold;
    color: #373737;
    justify-content: center;
    text-align: center;
    border-bottom: 2px solid #FFE600;
  }
}
@media (min-width: 75em) {
  .login-container .box--login-form {
    align-self: start;
  }
}
.login-container .box--login-form .fieldset > * + * {
  margin-top: 24px;
}
.login-container .box--login-form .field:not(.choice) .label {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 6px;
}
.login-container .box--register-account [data-content-type=heading] {
  margin: 12px 0;
  max-width: 240px;
  padding: 12px 0;
  font-size: 16px;
  border-bottom: 1px solid #FFE600;
}
.login-container .box--register-account [data-content-type=heading]::after {
  content: none;
}
.login-container .box .actions-toolbar {
  display: flex;
  flex-direction: column;
}
@media (min-width: 75em) {
  .login-container .box .actions-toolbar .primary {
    order: 3;
  }
}
@media (max-width: 74.99em) {
  .login-container .box .actions-toolbar .secondary {
    text-align: center;
    margin-top: 24px;
  }
}
@media (min-width: 75em) {
  .login-container .box .actions-toolbar .secondary {
    order: 1;
    margin-bottom: 24px;
    color: #7D7D7D;
  }
}
.login-container .box .actions-toolbar .secondary a {
  color: #373737;
  text-decoration: underline;
}
@media (min-width: 75em) {
  .login-container .box .actions-toolbar .secondary a {
    color: #7D7D7D;
  }
}
.login-container .box .have-account {
  margin-top: 24px;
}
@media (max-width: 74.99em) {
  .login-container .box .have-account {
    border-top: 2px solid rgba(125, 125, 125, 0.25);
    padding-top: 24px;
  }
}
.login-container .box .have-account a {
  text-decoration: underline;
}
.login-container .box .block-content {
  margin-top: 24px;
}
.login-container .box .block-content ul {
  margin: 0;
  padding: 0;
  list-style: none !important;
}
.login-container .box .button {
  width: 100%;
}
@media (min-width: 75em) {
  .login-container .box:not(.box--login-form) {
    display: flex;
    flex-direction: column;
  }
  .login-container .box:not(.box--login-form) .button {
    margin-top: auto;
  }
}

@media (min-width: 75em) {
  .login-title-text {
    width: 80%;
    margin: 0 auto 24px;
    text-align: center;
  }
}

.customer-account-create .login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
}
.customer-account-create .login-container .toggle-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.customer-account-create .login-container .toggle-buttons .toggle {
  width: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}
.customer-account-create .login-container .toggle-buttons .toggle a {
  width: 100%;
  display: block;
  color: #373737;
}
.customer-account-create .login-container .toggle-buttons .toggle.register {
  background-color: #ECECEC;
}
.customer-account-create .login-container .toggle-buttons .toggle.login {
  background-color: #FFFFFF;
}

.login-register-container {
  width: 100%;
  padding: 24px;
  background-color: #ECECEC;
  border-radius: 0 3px 3px 3px;
}
.login-register-container .form-create-account, .login-register-container .block-customer-login {
  width: 100%;
  background-color: #ECECEC;
}
.login-register-container .form-create-account .input-text, .login-register-container .block-customer-login .input-text {
  width: 100%;
  margin-bottom: 24px;
}
.login-register-container .form-create-account button, .login-register-container .block-customer-login button {
  width: 100%;
  margin-top: 24px;
}
.login-register-container .form-create-account .field.note, .login-register-container .form-create-account .block-title, .login-register-container .form-create-account .legend, .login-register-container .block-customer-login .field.note, .login-register-container .block-customer-login .block-title, .login-register-container .block-customer-login .legend {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.login-register-container .form-create-account .actions-toolbar .secondary, .login-register-container .block-customer-login .actions-toolbar .secondary {
  text-align: center;
}
.login-register-container .form-create-account .actions-toolbar .secondary .action.back, .login-register-container .block-customer-login .actions-toolbar .secondary .action.back {
  display: none;
}
.login-register-container .form-create-account .have-account, .login-register-container .block-customer-login .have-account {
  padding-top: 24px;
  margin-top: 12px;
  border-top: solid 1px #7D7D7D;
}
.login-register-container .form-create-account .register-btn, .login-register-container .block-customer-login .register-btn {
  margin-top: 12px;
  color: #009640;
  border: solid 1px #009640;
}
.login-register-container .form-create-account .register-btn a, .login-register-container .block-customer-login .register-btn a {
  display: block;
  width: 100%;
}
.login-register-container .form-create-account .action.remind, .login-register-container .block-customer-login .action.remind {
  color: #7D7D7D;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
}
.login-register-container .form-create-account.active, .login-register-container .block-customer-login.active {
  display: block;
  background-color: #ECECEC;
}
.login-register-container.active {
  border-radius: 3px 0 3px 3px;
}
.login-register-container .field.choice.newsletter {
  margin-top: 12px;
}

.block-new-company .block-title {
  margin-bottom: 12px;
}

#html-body .login-container .pagebuilder-column-group .pagebuilder-column-line {
  flex-wrap: nowrap;
  margin-bottom: 12px;
}
#html-body .login-container .pagebuilder-column-group .pagebuilder-column {
  flex-basis: auto;
  flex-grow: 0;
  width: auto;
  justify-content: center;
  align-items: center;
}
#html-body .login-container .pagebuilder-column-group .pagebuilder-column:first-of-type {
  flex-shrink: 0;
  width: 32px;
}
#html-body .login-container .pagebuilder-column-group .pagebuilder-column:last-of-type {
  flex-shrink: 1;
  margin-left: 12px;
}
#html-body .login-container .pagebuilder-column-group:last-of-type {
  margin-bottom: 24px;
}
#html-body .login-container .pagebuilder-column-group p {
  margin-bottom: 0;
}
#html-body .login-container .pagebuilder-column-group img {
  max-width: 32px;
  max-height: 32px;
}

.block.block-amazon-login {
  padding: 24px;
  font-size: 16px;
  background-color: #ECECEC;
  margin: 32px 0 0;
  text-align: center;
}