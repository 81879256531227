@import 'child-theme.variables.scss';

$border-radius--dashboard: 4px; // add correct border radius for account dashboard blocks as per designs
$color-danger: #FF0000;
$color-sku: #a2a2a2;
$color-grey--xlight: #F6F6F6;
$border-colour: #DFE3E8;
$color-black: #373737;
$compare-remove-icon-color: $color-black;
$header-icon-links-color: #ffffff !default;
