// Theme Variables
$color-primary: #009640;
$color-secondary: #FFE600;
$color-tertiary: #247d32;
$color-cta: #03a103;
$color-danger: #FF0000;

$color-black: #373737;
$color-grey--dark: #454545;
$color-grey: #7D7D7D;
$color-grey--light: #ECECEC;
$color-grey--xlight: #F6F6F6;
$color-white: #FFFFFF;

$color-sku: #a2a2a2;

$checkout-background-color: $color-grey--xlight;
$amblog-featured-background-color: $color-grey--xlight; // TODO: Investigate why variable doesn't overwrite parent theme variable
$compare-remove-icon-color: $color-black;
