///* ========================================================================
//   #HIDDEN-VISUALLY
//   ======================================================================== */

// Mixin to quickly apply accessible hiding to elements.

@mixin pinpoint-hidden-visually() {
  border: 0 !important; // sass-lint:disable-line no-important
  clip: rect(0 0 0 0) !important; // sass-lint:disable-line no-important
  clip-path: inset(50%) !important; // sass-lint:disable-line no-important
  height: 1px !important; // sass-lint:disable-line no-important
  margin: -1px !important; // sass-lint:disable-line no-important
  overflow: hidden !important; // sass-lint:disable-line no-important
  padding: 0 !important; // sass-lint:disable-line no-important
  position: absolute !important; // sass-lint:disable-line no-important
  white-space: nowrap !important; // sass-lint:disable-line no-important
  width: 1px !important; // sass-lint:disable-line no-important
}
